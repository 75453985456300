import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Badge from 'reactstrap/lib/Badge';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Table from 'reactstrap/lib/Table';
import Alert from 'reactstrap/lib/Alert';

import { OrderProcessStatus } from '@ttstr/api/orders';
import { revokeVoucherSwap } from '@ttstr/api';
import { requestOrder } from '@ttstr/actions';
import { LoadingSpinner } from '@ttstr/components';
import Address from '@ttstr/components/Address/Address';
import CartListingRow from '@ttstr/components/ShoppingCart/CartListingRow';
import Currency from '@ttstr/components/Intl/Currency';
import CustomerFromOrder from '@ttstr/components/Order/CustomerFromOrder';
import DateComponent from '@ttstr/components/Intl/DateComponent';
import Percent from '@ttstr/components/Intl/Percent';
import { useShallowEqualSelector, useActions } from '@ttstr/utils';
import { AppState } from '@ttstr/reducers';
import { getOrder } from '@ttstr/reducers/orders';

import PaymentInfo from '@ttstr/components/Order/PaymentInfo';
import C19Personalization from '@ttstr/components/Order/C19Personalization';

interface OwnProps {
  orderId: string;
  xtoken?: string;
  closeIcon?: React.ReactNode;
}

type Props = Readonly<OwnProps>;

const OrderDetails: React.FC<Props> = ({ orderId, xtoken, closeIcon }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const { order } = useShallowEqualSelector((state: AppState) => {
    return {
      order: getOrder(state.Orders.orders, Number(orderId)),
    };
  });
  const { requestOrder } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    (async () => {
      if (!orderId) return;
      try {
        setLoading(true);
        await requestOrder(Number(orderId), xtoken);
      } finally {
        setLoading(false);
      }
    })();
  }, [orderId, xtoken]);

  const revoke = async (path: string) => {
    try {
      setLoading(true);
      await revokeVoucherSwap(path);
      await requestOrder(Number(orderId), xtoken);
    } finally {
      setLoading(false);
    }
  };

  const walletFiles = React.useMemo(() => {
    if (loading) return [];
    if (!order) return [];
    return order.vouchers.map((v) => v.pkpass_file).filter(Boolean);
  }, [loading, order]);

  if (loading) return <LoadingSpinner label={t('LOADING.DATA')} />;
  if (!order) return <p className="text-danger">{t('ORDERS.DETAIL.ERROR')}</p>;

  return (
    <div className="order-details">
      <Row>
        <Col lg="4">
          <fieldset>
            <legend>
              {t('CUSTOMER.DATA')}
              <i className="fal fa-user ml-2 text-muted" />
            </legend>
            <Address {...order} />
          </fieldset>
          {order.use_shipping_address && (
            <fieldset>
              <legend>
                {t('CUSTOMER.SHIPPING_ADDRESS')}
                <i className="fal fa-shipping-fast ml-2 text-muted" />
              </legend>
              <Address
                firstname={order.shipping_firstname}
                lastname={order.shipping_lastname}
                companyname={order.shipping_companyname}
                street={order.shipping_street}
                address_addition={order.shipping_address_addition}
                state={order.shipping_state}
                zip_code={order.shipping_zip_code}
                city={order.shipping_city}
                country={order.shipping_country}
              />
            </fieldset>
          )}
          {/* TODO: put in global file + add shopconfig flag */}
          {order.voucher_file && (
            <Button
              className="mb-5"
              color="secondary"
              tag="a"
              href={order.voucher_file.replace('/T/', '/I/')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fal fa-file-pdf" /> {'Rechnung herunterladen'}
            </Button>
          )}

          {order.registerable && (
            <CustomerFromOrder
              orderId={order.id}
              xtoken={order.simple_token}
              email={order.email}
              color="warning"
              closeIcon={closeIcon}
            />
          )}
        </Col>
        <Col lg="8">
          {order.payment_status !== OrderProcessStatus.done && order.payment_method_info && (
            <PaymentInfo info={order.payment_method_info} color="warning" />
          )}
          <fieldset>
            <legend>{t('ORDERS.DETAIL.OVERVIEW')}</legend>
            <Table striped hover className="tt-listing" responsive>
              <thead>
                <tr>
                  <th colSpan={2}>{t('ORDERS.DETAIL.ORDER_NO', order)}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {t('ORDERS.DETAIL.ORDERED_AT')}{' '}
                    <strong>
                      <DateComponent value={order.ordered_at} />
                    </strong>
                  </td>
                  <td>
                    {t('ORDERS.DETAIL.VALUE')}{' '}
                    <strong>
                      <Currency value={order.value} />
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('ORDERS.DETAIL.PAID_PER')} <strong>{order.payment_method}</strong>
                  </td>
                  <td>
                    <Badge color={statusToColor(order.payment_status)}>
                      {t(`ORDERS.DETAIL.STATUS.${order.payment_status.toUpperCase()}`)}
                      {<> {t('ORDERS.DETAIL.OPEN_AMOUNT', { amount: order.balance })}</>}
                    </Badge>
                    {order.needs_online_payment && (
                      <Button
                        tag={Link}
                        to={`/cart/${order.cart_token}/status`}
                        type="button"
                        color="primary"
                        size="sm"
                        className="ml-2"
                      >
                        {t('ORDERS.DETAIL.COMPLETE_PAYMENT')}
                      </Button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('ORDERS.DETAIL.SHIPPED_PER')} <strong>{order.shipping_method}</strong>
                  </td>
                  <td>
                    <Badge color={statusToColor(order.shipping_status)}>
                      {t(`ORDERS.DETAIL.STATUS.${order.shipping_status.toUpperCase()}`)}
                    </Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </fieldset>

          {/* TODO: text in locales */}
          <Table striped hover className="tt-listing" responsive>
            <tbody>
              {order.personalization && order.cancelled !== true && (
                <tr>
                  <th scope="row">{t('ORDERS.DETAIL.PERSONALIZATION.PERSONALIZED_FOR')}</th>
                  <td>
                    {/* wenn all_done, kann nicht mehr repersonalisiert werden */}
                    {order.personalization.all_done ? (
                      <>
                        <Button color="secondary" disabled>
                          {t('ORDERS.DETAIL.PERSONALIZATION.CANT_BE_CHANGED')}
                        </Button>
                        {order.new_voucher_swap_path && (
                          <Alert color="danger" className="mt-3 mb-0">
                            {'Um die Personalisierung zu ändern, benutze bitte '}
                            <a href={order.new_voucher_swap_path} target="_blank" rel="noopener noreferrer">
                              {'unser Weiterverkaufs-Tool'}
                            </a>
                            {', um die Tickets an den/die neuen KarteninhaberIn zu übertragen.'}
                            <br />
                            {
                              'Löse deinen Weiterverkaufscode ganz einfach nach der Erstellung selbst ein und personalisiere deine neuen Tickets auf die gewünschten Namen.'
                            }
                          </Alert>
                        )}
                      </>
                    ) : (
                      <C19Personalization orderId={orderId} xtoken={xtoken} {...order.personalization} />
                    )}
                  </td>
                </tr>
              )}
              {(order.voucher_file || Boolean(walletFiles.length)) && (
                <tr>
                  <th scope="row">{t('ORDERS.DETAIL.TICKETS')}</th>
                  <td>
                    {/* {!order.voucher_file && !walletFiles.length && (
                      <Badge color="secondary">{t('ORDERS.DETAIL.NO_TICKETS_GENERATED')}</Badge>
                    )} */}
                    <ul className="list-unstyled mb-0">
                      {order.voucher_file ? (
                        <li className="mb-2">
                          <Button
                            color="secondary"
                            block
                            tag="a"
                            href={order.voucher_file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fal fa-file-pdf" /> {t('ORDERS.DETAIL.TICKETS_DOWNLOAD')}
                          </Button>
                        </li>
                      ) : null}
                      {walletFiles.length
                        ? walletFiles.map((file, index) => (
                            <li key={index} className="mb-2">
                              <Button
                                color="secondary"
                                block
                                tag="a"
                                href={file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fal fa-ticket" />{' '}
                                {t('ORDERS.DETAIL.WALLET_DOWNLOAD', { number: index + 1 })}
                              </Button>
                            </li>
                          ))
                        : null}
                    </ul>
                  </td>
                </tr>
              )}
              {Boolean(order.bought_coupons.length) && (
                <tr>
                  <th scope="row">{t('ORDERS.DETAIL.COUPONS')}</th>
                  <td>
                    <ul className="list-unstyled mb-0">
                      {order.bought_coupons.map((coupon, index) => (
                        <li key={index} className="mb-2">
                          <Button
                            color="secondary"
                            block
                            tag="a"
                            href={coupon.download}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={`coupon-${coupon.token}`}
                          >
                            <i className="fal fa-file-pdf" /> {t('ORDERS.DETAIL.COUPONS_DOWNLOAD')}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Table striped hover className="tt-listing" responsive>
            <thead>
              <tr>
                <th scope="col">{t('CART.ITEMS')}</th>
                <th className="text-center" scope="col">
                  {/* eslint-disable-line react/jsx-no-literals */}
                  &nbsp;
                </th>
                <th className="text-right" scope="col">
                  {t('CART.BREAKDOWN')}
                </th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item) => (
                <CartListingRow key={item.id} item={item} readOnly />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={2} className="text-right" scope="row">
                  {t(`CART.SUM`)}
                </th>
                <td className="text-right">
                  <strong>
                    <Currency value={order.value} />
                  </strong>
                </td>
              </tr>
              {Object.entries(order.vat_rates).map(([rate, value]) => (
                <tr key={rate} className="small text-muted">
                  <td colSpan={2} className="text-right">
                    {t('CART.VAT')} <Percent value={Number(rate) / 100} />
                  </td>
                  <td className="text-right">
                    <Currency value={Number(value)} />
                  </td>
                </tr>
              ))}
            </tfoot>
          </Table>

          {order.new_voucher_swap_path && (
            <fieldset>
              <legend>{'Tickets verkaufen'}</legend>
              <p>
                {`Du kannst die Codes mit deinen Freunden oder in Sozialen Netzwerken teilen.
                  Mit dem Code erhalten andere die Möglichkeit dein Ticket zu erwerben.
                  Der Käufer zahlt direkt an den Ticketshop, wodurch kein Geldtransfer zwischen dir und dem Käufer notwendig ist.`}
                <br />
                <br />
                {'Der Code kann unter '}
                <a href={'/swp'} target="_blank" rel="noopener noreferrer">{`${location.host}/swp`}</a>
                {' eingelöst werden.'}
              </p>
              <p>
                <Button
                  color="secondary"
                  // block
                  tag="a"
                  href={order.new_voucher_swap_path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'Tickets zum Weiterverkauf anbieten'}
                </Button>
              </p>
              <Table striped hover className="tt-listing" responsive>
                <tbody>
                  {order.items
                    .map((i) => i.voucher_swaps)
                    .flat()
                    .filter((i) => i)
                    .map((swap) => (
                      <tr key={swap.token}>
                        <td>
                          {swap.status === 'sold' ? (
                            <s>
                              <span className="swap-token">{swap.token}</span>
                            </s>
                          ) : (
                            <span className="swap-token">{swap.token}</span>
                          )}{' '}
                          {swap.status === 'carted' && <i className="fal fa-shopping-cart" />}
                          {(swap.status === 'paid' || swap.status === 'sold') && (
                            <i className="fal fa-money-bill-wave" />
                          )}
                          {swap.status === 'sold' && ' ' && <i className="fal fa-check" />}
                          {swap.status === 'new' && (
                            <Button
                              color="link"
                              className="float-right"
                              size="sm"
                              // block
                              tag="button"
                              onClick={() => revoke(swap.revoke_path)}
                            >
                              {'Angebot zurückziehen'}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </fieldset>
          )}
        </Col>
      </Row>
    </div>
  );
};

function statusToColor(status: string) {
  switch (status) {
    case 'failure':
      return 'danger';
    case 'pending':
      return 'warning';
    case 'done':
      return 'success';
    case 'hold':
      return 'dark';
    case 'unapproved': // fall-through
    case 'processing': // fall-through
    case 'new': // fall-through
    default:
      return 'secondary';
  }
}

const mapDispatchToProps = {
  requestOrder,
};

export default React.memo(OrderDetails);
