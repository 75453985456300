import React from 'react';

import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { Article } from '@ttstr/api/products';
import {
  AuthProvider,
  AuthRoute,
  IntlProvider,
  Loading,
  ProductDetailsProvider,
  ScrollToTop,
  ShopConfigProvider,
  ShoppingFinisher,
  ContentrProvider,
  ProductDetailLinkProvider,
} from '@ttstr/components';

import { FaqView } from '@ttstr/components/Views';

import shopConfig from '../shop-config.json';
import MsDockville from '../MsDockville';
import MsDockvilleMerch from '../MsDockvilleMerch';
import MsDockvilleHASPA from '../MsDockvilleHASPA';
import Spektrum from '../Spektrum';
import Vogelball from '../Vogelball';
import Habitat from '../Habitat';
import MsArtville from '../MsArtville';
import EmailSent from './Customer/EmailSent';
import RegisterNewsletter from './Customer/RegisterNewsletter';
import UnsubscribeNewsletter from './Customer/UnsubscribeNewsletter';
import NewsletterSubscribeFinisher from './Customer/NewsletterSubscribeFinisher';
import NewsletterUnsubscribeFinisher from './Customer/NewsletterUnsubscribeFinisher';
import Subscribed from './Customer/Subscribed';
import Unsubscribed from './Customer/Unsubscribed';

import { configureStore } from './store';

import i18nInit from './i18n';

import AbstractProductDetail from './AbstractProductDetail';
import Cart from './Cart';
import CartExpired from './CartExpired';
import Checkout from './Checkout';
import Conversion from './Conversion';
import Customer from './Customer';
import Confirm from './Customer/Confirm';
import Login from './Customer/Login';
import Logout from './Customer/Logout';
import Register from './Customer/Register';
import Registered from './Customer/Registered';
import Footer from './Footer';
import G8Page from './G8Page';
import Header from './Header';
import Imprint from './Imprint';
import NotFound from './NotFound';
import OrdersDetail from './Orders/Detail';
import PaymentAbort from './PaymentAbort';
import PaymentDone from './PaymentDone';
import Privacy from './Privacy';
import ProductListing from './ProductListing';
import ResetPassword from './ResetPassword';
import TermsAndConditions from './TermsAndConditions';
import TicketListing from './TicketListing';
import Home from './Home';
import Contact from './Contact';
import Faq from './Faq';

const productDetailLink = (product: Article, { canonicalUrl = '' } = {}) => {
  const slug = product.ptitle ?? '';
  if (canonicalUrl) return `${canonicalUrl}/product/${product.id}/${slug}`;
  return `/product/${product.id}/${slug}`;
};

export enum CATEGORY_ID {
  MSDOCKVILLE = 3283,
  SPEKTRUM = 3284,
  VOGELBALL = 3285,
  HABITAT = 3286,
  MSARTVILLE = 3573,
  MSDOCKVILLExHASPA = 4309,
}

const store = configureStore();

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <Helmet titleTemplate={shopConfig.titleTemplate} />

      <Router>
        <ScrollToTop>
          <ShopConfigProvider value={shopConfig}>
            <I18nextProvider i18n={i18nInit}>
              <Loading>
                <Switch>
                  <Route exact path="/g8" component={G8Page} />
                  <Route>
                    <AuthProvider>
                      <ContentrProvider>
                        <IntlProvider>
                          <ProductDetailLinkProvider generator={productDetailLink}>
                            <ProductDetailsProvider>
                              <Header />
                              <main id="main">
                                <Loading>
                                  <Switch>
                                    <Redirect exact from="/" to="/home" />

                                    <Route exact path="/home" component={Home} />
                                    <Route exact path="/rueckgabe" component={Contact} />
                                    <Route exact path="/tickets" component={TicketListing} />
                                    <Route exact path="/products" component={ProductListing} />
                                    <Route exact path="/product/:id/:slug?" component={AbstractProductDetail} />

                                    <Route path="/msdockvillexhaspajoker" component={MsDockvilleHASPA} />
                                    <Route path="/category/msdockville/merch" component={MsDockvilleMerch} />
                                    <Route path="/category/msdockville" component={MsDockville} />
                                    <Route path="/category/spektrum" component={Spektrum} />
                                    <Route path="/category/vogelball" component={Vogelball} />
                                    <Route path="/category/habitat" component={Habitat} />
                                    <Route path="/category/msartville" component={MsArtville} />

                                    <Route exact path="/heute" render={() => <TicketListing onlyToday />} />

                                    <Route exact path="/cart" component={Cart} />
                                    <Route exact path="/cart/expired" component={CartExpired} />
                                    <Route exact path="/cart/:cart_token/status" component={Conversion} />

                                    <Route exact path="/payment/:contractor/done" component={PaymentDone} />
                                    <Route exact path="/payment/:contractor/abort" component={PaymentAbort} />

                                    <Route path="/checkout/:step?" component={Checkout} />

                                    <Route exact path="/customer/login" component={Login} />
                                    <Route exact path="/customer/logout" component={Logout} />
                                    <Route exact path="/customer/register" component={Register} />
                                    <Route exact path="/customer/registered" component={Registered} />
                                    <Route exact path="/customer/reset-password/:token" component={ResetPassword} />
                                    <Route exact path="/customer/confirm/:confirmationToken" component={Confirm} />
                                    <AuthRoute path="/customer" component={Customer} />

                                    <Route exact path="/newsletter" component={RegisterNewsletter} />
                                    <Route
                                      exact
                                      path="/newsletter/confirm/:token"
                                      component={NewsletterSubscribeFinisher}
                                    />
                                    <Route exact path="/newsletter/subscribed" component={Subscribed} />
                                    <Route exact path="/newsletter/unsubscribe" component={UnsubscribeNewsletter} />
                                    <Route exact path="/newsletter/email_sent" component={EmailSent} />
                                    <Route exact path="/newsletter/:token" component={NewsletterUnsubscribeFinisher} />
                                    <Route exact path="/newsletter/unsubscribed" component={Unsubscribed} />

                                    <Route exact path="/orders/:orderId/xtoken/:xtoken" component={OrdersDetail} />

                                    <Route exact path="/imprint" component={Imprint} />
                                    <Route exact path="/terms" component={TermsAndConditions} />
                                    <Route exact path="/privacy" component={Privacy} />

                                    {/* <Route exact path="/faq" component={Faq} /> */}
                                    <Route exact path="/faq" component={FaqView} />

                                    <Route component={NotFound} />
                                  </Switch>
                                </Loading>
                              </main>
                            </ProductDetailsProvider>
                          </ProductDetailLinkProvider>

                          <footer id="footer" className="d-print-none">
                            <Footer />
                          </footer>

                          <Switch>
                            <Route path="/cart" />
                            <Route path="/customer" />
                            <Route path="/checkout" />
                            <Route component={ShoppingFinisher} />
                          </Switch>
                        </IntlProvider>
                      </ContentrProvider>
                    </AuthProvider>
                  </Route>
                </Switch>
              </Loading>
            </I18nextProvider>
          </ShopConfigProvider>
        </ScrollToTop>
      </Router>
    </ReduxProvider>
  );
};

export default React.memo(App);
