import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container, LoadingSpinner } from '@ttstr/components';

import { delSubscription } from '@ttstr/api';

interface RouterParams {
  token: string;
}

const NewsletterUnsubscribeFinisher: React.FC = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState<boolean>(null);
  const [processing, setProcessing] = React.useState<boolean>(true);
  const params = useParams<RouterParams>();

  async function deleteSubscription(token: string) {
    try {
      await delSubscription(token);
    } catch (error) {
      setProcessing(false);
      setSuccess(false);
    } finally {
      setProcessing(false);
      setSuccess(true);
    }
  }

  React.useEffect(() => {
    deleteSubscription(params.token);
  }, []);

  return (
    <article className="mt-5 newsletter-unsubscribe-finisher">
      <Container>
        <h1 className="text-center mb-4">{t('NEWSLETTER.UNSUBSCRIBED.TITLE')}</h1>
        <Row>
          <Col>
            {processing ?? <LoadingSpinner label={t('NEWSLETTER.UNSUBSCRIBED.TITLE')} />}
            {success ?? (
              <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                  <p className="text-justify text-center">
                    {t('NEWSLETTER.UNSUBSCRIBED.DESCRIPTION')}
                  </p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default React.memo(NewsletterUnsubscribeFinisher);
