import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { ProductFilter } from '@ttstr/api';
import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { SushiProps } from '@ttstr/components/Sushi/Sushi';

interface OwnProps {
  filter?: ProductFilter;
  sushiElement: React.ComponentType<SushiProps>;
  sushiContainerClassName?: string;
  columnClassName?: string;
}

type Props = Readonly<OwnProps>;

const ArticleListing: React.FC<Props> = ({ filter, sushiElement, sushiContainerClassName, columnClassName }) => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts(filter);
  }, [language]);

  return (
    <article>
      <Container className="list-page">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
          <body className="product-listing-page" />
        </Helmet>

        <Masonry
          id="product-listing"
          type={sushiElement}
          sushiContainerClassName={sushiContainerClassName}
          columnClassName={columnClassName}
          products={products}
          filters="off"
        />
        {loading && <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ArticleListing);
