/* eslint-disable react/jsx-no-literals */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, ContentrArea } from '@ttstr/components';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  return (
    <article className="my-5">
      <Helmet>
        <title>{t(`REFUND.TITLE`)}</title>
      </Helmet>
      <Container>
        <ContentrArea id="contact-content" />
      </Container>
    </article>
  );
};

export default React.memo(Contact);
