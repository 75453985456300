import classNames from 'classnames';

import React from 'react';

import { Switch, Route, useRouteMatch, NavLink as RouterNavLink, Link } from 'react-router-dom';

import Nav from 'reactstrap/lib/Nav';
import Navbar from 'reactstrap/lib/Navbar';
import NavItem from 'reactstrap/lib/NavItem';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavbarToggler from 'reactstrap/lib/NavbarToggler';
import Collapse from 'reactstrap/lib/Collapse';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { Article } from '@ttstr/api';
import { ContentrArea, ProductDetailLinkProvider } from '@ttstr/components';
import { defaultTogglerIcon } from '@ttstr/components/Navigation/Navigation';
import { useToggle } from '@ttstr/utils';

import AbstractProductDetail from './src/AbstractProductDetail';
import NotFound from './src/NotFound';
import ArticleListing from './src/ArticleListing';
import { CATEGORY_ID } from './src/App';
import Nigiri from './src/Nigiri';
import HaspaNigiri from './src/HaspaNigiri';

const productDetailLink = (product: Article, { canonicalUrl = '' } = {}) => {
  const slug = product.ptitle ?? '';
  if (canonicalUrl) return `${canonicalUrl}/msdockvillexhaspajoker/product/${product.id}/${slug}`;
  return `/msdockvillexhaspajoker/product/${product.id}/${slug}`;
};

const MsDockvilleHASPA: React.FC = () => {
  const { path } = useRouteMatch();

  const [showMenu, toggleShowMenu] = useToggle(false);

  return (
    <section className="msdockville--haspa">
      <Navbar dark color="msdockville--haspa" className="sticky-top" expand="xs">
        <NavbarBrand tag={RouterNavLink} to={path}>
          <img
            src={require('./src/assets/images/MSDVxHASPA-logo.png')}
            alt="MS Dockville x HASPA"
            loading="lazy"
            height="30"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggleShowMenu}>
          <span className={classNames({ 'is-active': showMenu }, 'hamburger hamburger--squeeze')}>
            {defaultTogglerIcon}
          </span>
        </NavbarToggler>
        <Collapse isOpen={showMenu} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <a href="https://www.msdockville.de/" rel="noreferrer noopener" target="_blank" className="nav-link">
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span>Zur Website</span>
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://www.instagram.com/dockville/"
                rel="noreferrer noopener"
                target="_blank"
                className="nav-link"
              >
                <i className="fab fa-instagram" />
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span className="sr-only">instagram</span>
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://www.facebook.com/dockville/"
                rel="noreferrer noopener"
                target="_blank"
                className="nav-link"
              >
                <i className="fab fa-facebook" />
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span className="sr-only">facebook</span>
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://www.youtube.com/dockville"
                rel="noreferrer noopener"
                target="_blank"
                className="nav-link"
              >
                <i className="fab fa-youtube" />
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span className="sr-only">youtube</span>
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <ProductDetailLinkProvider generator={productDetailLink}>
        <Switch>
          <Route exact path={path}>
            <img
              src={require('./src/assets/images/MSDVxHASPA-header.jpg')}
              alt="MS Dockville"
              loading="lazy"
              className="cover-photo"
            />
            <ContentrArea id="above-listing" />

            {/* <Container>
              <Row className="d-flex justify-items-center justify-content-center mt-4">
                <Col className="mt-2">
                  <Link
                    to="/category/msdockville"
                    className="btn btn-lg btn-block btn-outline-primary h-100 active"
                    aria-pressed
                  >
                    {'Tickets'}
                  </Link>
                </Col>
                <Col className="mt-2">
                  <Link to="/category/msdockville/merch" className="btn btn-lg btn-block btn-outline-primary h-100">
                    {'Merchandise'}
                  </Link>
                </Col>
              </Row>
            </Container> */}

            <ArticleListing
              filter={{ type: ['Ticket', 'Package'], category_ids: CATEGORY_ID.MSDOCKVILLExHASPA }}
              sushiElement={HaspaNigiri}
              sushiContainerClassName="nigiri-container"
              columnClassName="col-12"
            />
            <ContentrArea id="below-listing" />
          </Route>
          <Route exact path={`${path}/product/:id/:slug?`} component={AbstractProductDetail} />
          <Route component={NotFound} />
        </Switch>
      </ProductDetailLinkProvider>
    </section>
  );
};

export default React.memo(MsDockvilleHASPA);
