// @ts-nocheck
import React from 'react';

import { Link } from 'react-router-dom';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Container, ContentrArea } from '@ttstr/components';

import msdv from './assets/images/dockville-kachel.jpg';
import spkt from './assets/images/spektrum-kachel.jpg';
import vgbl from './assets/images/vogelball-kachel.jpg';
import hbtt from './assets/images/habitat-kachel.png';
import artv from './assets/images/artville-kachel-2209.jpg';
import kachel from './assets/images/kachel-gutschein.jpg';

const Home: React.FC = () => {
  return (
    <>
      <img
        src={require('./assets/images/HomeHeaderImg.png')}
        alt="In die Luft gehobene Hände einer Menschenmenge vor einer Bühne"
        loading="lazy"
        className="cover-photo"
      />
      <Container>
        <ContentrArea id="home" />
        <Row className="mt-5">
          <Col xs="12" sm="6" lg="6" className="mb-5 text-center">
            <Link to="/category/msdockville">
              <img
                src={msdv}
                alt="Tanzende Menschenmenge in Dunkelheit und Scheinwerferlicht unter Bäumen"
                className="img-fluid scale-on-hover"
              />
            </Link>
          </Col>

          <Col xs="12" sm="6" lg="6" className="mb-5 text-center">
            <Link to="/category/spektrum">
              <img src={spkt} alt="Spektrum Festival" className="img-fluid scale-on-hover" />
            </Link>
          </Col>

          <Col xs="12" sm="6" lg="6" className="mb-5 text-center">
            <Link to="/category/vogelball">
              <img
                src={vgbl}
                alt="Scenenausschnitt mit fokus auf leichte Bekleidung und behaarte Haut. 'Vogelball' in großen Buchstaben über dem Motiv"
                className="img-fluid scale-on-hover"
              />
            </Link>
          </Col>

          <Col xs="12" sm="6" lg="6" className="mb-5 text-center">
            <Link to="/category/habitat">
              <img src={hbtt} alt="Habitat Festival" className="img-fluid scale-on-hover" />
            </Link>
          </Col>

          <Col xs="12" sm="6" lg="6" className="mb-5 text-center">
            <Link to="/product/53548/gutschein ">
              <img src={kachel} alt="Ticket Gutschein" className="img-fluid scale-on-hover" />
            </Link>
          </Col>

          {/* <Col xs="12" sm="6" lg="4" className="mb-5 text-center">
            <Link to="/category/msartville">
              <img src={artv} alt="Artville Festival" className="img-fluid scale-on-hover" />
            </Link>
          </Col> */}
        </Row>
        <ContentrArea id="home-below-listing" />
      </Container>
    </>
  );
};

export default React.memo(Home);
