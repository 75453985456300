import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { AppState } from '@ttstr/reducers';
import Container from 'reactstrap/lib/Container';
import { CheckoutWrapper } from '@ttstr/components/Checkout';
import { useShallowEqualSelector } from '@ttstr/utils';

declare global {
  interface Window {
    dataLayer: any | undefined;
    gtag: any | undefined;
    fbq: any | undefined;
  }
}

const Checkout: React.FC = () => {
  const { t } = useTranslation();
  const { price, items } = useShallowEqualSelector(mapStateToProps);
  const history = useHistory();

  React.useEffect(() => {
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'begin_checkout',
        currency: 'EUR',
        value: String(price),
        items: items?.map((i) => {
          return {
            items_id: i.variant?.article?.id,
            item_name: i.variant?.article?.ptitle,
            price: i.variant?.price,
            quantity: i.quantity,
          };
        }),
      });
    }
  }, []);

  // for gtag Purchase Event
  React.useEffect(() => {
    if (history.location.pathname === '/checkout/confirm') {
      const button = document.getElementById('confirm-purchase-button');

      if (button && 'dataLayer' in window) {
        const func1 = function () {
          window.dataLayer.push({
            event: 'purchase',
            currency: 'EUR',
            value: String(price),
            items: items?.map((i) => {
              return {
                items_id: i.variant?.article?.id,
                item_name: i.variant?.article?.ptitle,
                price: i.variant?.price,
                quantity: i.quantity,
              };
            }),
          });
        };
        button.addEventListener('click', func1);
      }
    }
  }, [history.location.pathname]);

  return (
    <article className="mt-5">
      <Container>
        <Helmet>
          <title>{t('CHECKOUT.TITLE')}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t('CHECKOUT.TITLE')}</h1>
      </Container>
      <CheckoutWrapper />
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const price = state.Cart.cart?.price_after_coupons;
  const items = state.Cart.cart?.items;

  return {
    price,
    items,
  };
};

export default React.memo(Checkout);
