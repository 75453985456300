import React from 'react';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, ContentrArea, LoadingSpinner, Maki2020, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

type Props = {
  onlyToday: boolean;
};

const TicketListing: React.FC<Props> = ({ onlyToday = false }) => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();
  const history = useHistory();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  const datesAreOnSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  if (onlyToday) {
    const today = new Date();
    const todaysEvent = tickets.find((t) => datesAreOnSameDay(t.valid_start_on, today));

    if (todaysEvent) {
      history.push(`/product/${todaysEvent.id}`);
    }
  }

  /**
   * Dieser Block ist für die Kampagnen Funktion im Backend.
   * Wenn man von einer Facebook Kampagne kommt wird man auf die tt Kampagne weitergeleitet.
   */
  if (location.search.startsWith('?C=')) {
    return <Redirect to={'/C/' + location.search.replace('?C=', '')} />;
  }

  return (
    <article className="py-5 ticket-listing">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
      </Helmet>

      <Container className="list-page">
        {/* <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1> */}
        <a href="https://stereolicious.tickettoaster.de" rel="noreferrer" target="_blank">
          <img src={require('./assets/images/headercl.png')} alt="Cashless aufladen" className="w-100 img-fluid" />
        </a>
        <ContentrArea id="Ticketlisting" />

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry id="ticket-listing" products={tickets} type={Maki2020} filters="off" />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
