import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Ticket, Variant } from '@ttstr/api/products';
import {
  Container,
  ProductDetailHeader,
  ProductInfoBar,
  VariantListing,
  NativeSlider,
  TicketMarkup,
} from '@ttstr/components';

interface OwnProps {
  readonly product: Ticket;
}

type Props = Readonly<OwnProps>;

const TicketDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);

  // add_to_cart
  const addToCortTrackingHook = (variant: Variant, quantity: number) => {
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'add_to_cart',
        currency: 'EUR',
        value: String(variant.total_price),
        items: [
          {
            item_id: product.id,
            item_name: product.ptitle,
            price: String(variant.total_price),
            currency: 'EUR',
          },
        ],
        quantity: String(quantity),
      });
    }
  };

  // for gtag view_item Event
  React.useEffect(() => {
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'view_item',
        currency: 'EUR',
        value: String(product.min_price),
        items: [
          {
            item_id: product.id,
            item_name: product.ptitle,
            price: product.max_price,
            currency: 'EUR',
          },
        ],
      });
    }

    if ('fbq' in window) {
      window.fbq('track', 'ViewContent', {
        content_category: 'Detail Page',
        content_name: product.title,
      });
    }
  }, []);

  return (
    <article className="ticket-detail" itemScope itemType="http://schema.org/Event">
      <Helmet>
        <body className="ticket-detail-page" />
      </Helmet>
      <TicketMarkup ticket={product} dontChangeMetaDescription />

      <ProductDetailHeader
        className="product-header tt-sticky-top"
        background={product.hero_image.url}
        // image={product.hero_image.url}
      >
        <NativeSlider
          className="mb-0"
          images={images.length ? images : [product.hero_image.url]}
          // thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
          // thumbContainerClassName="random-rotation scribble-hover-overlay"
          // thumbClassName="random-rotation-object"
        />
      </ProductDetailHeader>

      <div className="product-content">
        <Card body className="article-title">
          <Container className="text-center">
            {product.supertitle && <span className="super-title">{product.supertitle}</span>}
            <h1 className="h2 my-0">
              <span className="title" itemProp="name">
                {product.title}
              </span>
              <small className="sub-title">{product.subtitle}</small>
            </h1>
          </Container>
        </Card>
        <ProductInfoBar product={product} />

        <Container className="detail-page mt-2 mt-md-5">
          <Row>
            <Col lg={{ size: true, order: 'last' }}>
              <div className="product-info tt-sticky-top">
                <h2 className="product-title">{t('TICKET.CHOOSE')}</h2>
                <VariantListing
                  product={product}
                  className="mb-5 bg-white"
                  addToCartTrackingHook={addToCortTrackingHook}
                />
              </div>
            </Col>
            {product.description && (
              <Col lg={{ size: true, order: 'first' }}>
                <h2 className="product-title">{t(`PRODUCT.INFORMATION`)}</h2>
                <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
              </Col>
            )}
            {/* {product.merchant_id && <MerchantInfo merchantId={product.merchant_id} />} */}
          </Row>
        </Container>
      </div>
    </article>
  );
};

export default React.memo(TicketDetail);
