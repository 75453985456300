import classNames from 'classnames';

import React from 'react';

import { Switch, Route, useRouteMatch, NavLink as RouterNavLink } from 'react-router-dom';

import Nav from 'reactstrap/lib/Nav';
import Navbar from 'reactstrap/lib/Navbar';
import NavItem from 'reactstrap/lib/NavItem';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavbarToggler from 'reactstrap/lib/NavbarToggler';
import Collapse from 'reactstrap/lib/Collapse';

import { Article } from '@ttstr/api';
import { ContentrArea, Nigiri, ProductDetailLinkProvider } from '@ttstr/components';
import { defaultTogglerIcon } from '@ttstr/components/Navigation/Navigation';
import { useToggle } from '@ttstr/utils';

import AbstractProductDetail from './src/AbstractProductDetail';
import NotFound from './src/NotFound';
import ArticleListing from './src/ArticleListing';
import { CATEGORY_ID } from './src/App';
import Maki2020 from './src/Maki2020';

const productDetailLink = (product: Article, { canonicalUrl = '' } = {}) => {
  const slug = product.ptitle ?? '';
  if (canonicalUrl) return `${canonicalUrl}/category/spektrum/product/${product.id}/${slug}`;
  return `/category/spektrum/product/${product.id}/${slug}`;
};

const Spektrum: React.FC = () => {
  const { path } = useRouteMatch();

  const [showMenu, toggleShowMenu] = useToggle(false);

  return (
    <section className="spektrum">
      <Navbar dark color="spektrum" className="sticky-top" expand="xs">
        <NavbarBrand tag={RouterNavLink} to={path}>
          {/* <img src={require('./src/assets/images/spektrum-logo.svg')} alt="Spektrum" loading="lazy" height="50" /> */}
        </NavbarBrand>
        <NavbarToggler onClick={toggleShowMenu}>
          <span className={classNames({ 'is-active': showMenu }, 'hamburger hamburger--squeeze')}>
            {defaultTogglerIcon}
          </span>
        </NavbarToggler>
        <Collapse isOpen={showMenu} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <a href="https://spektrum.ms/" rel="noreferrer noopener" target="_blank" className="nav-link">
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span>Zur Website</span>
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://www.instagram.com/spektrumhh/"
                rel="noreferrer noopener"
                target="_blank"
                className="nav-link"
              >
                <i className="fab fa-instagram" />
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span className="sr-only">instagram</span>
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://www.facebook.com/spektrum.ms/"
                rel="noreferrer noopener"
                target="_blank"
                className="nav-link"
              >
                <i className="fab fa-facebook" />
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span className="sr-only">facebook</span>
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <ProductDetailLinkProvider generator={productDetailLink}>
        <Switch>
          <Route exact path={path}>
            <img
              src={require('./src/assets/images/spektrum-header.jpg')}
              alt="Spektrum"
              loading="lazy"
              className="cover-photo"
            />
            <ContentrArea id="above-listing" />
            <ArticleListing
              filter={{ type: ['Ticket', 'Package'], category_ids: CATEGORY_ID.SPEKTRUM }}
              sushiElement={Maki2020}
            />
            <ContentrArea id="below-listing" />
          </Route>
          <Route exact path={`${path}/product/:id/:slug?`} component={AbstractProductDetail} />
          <Route component={NotFound} />
        </Switch>
      </ProductDetailLinkProvider>
    </section>
  );
};

export default React.memo(Spektrum);
